<template>
    <div class="box">
      <van-search right-icon="phone" v-model="mobile" :label="label" input-align="right" :error="error" shape="round" :clearable="false" background="#f5f5f5" placeholder="输入号码获取优惠"/>
    </div>

  <div class="recharge-list-view">

      <div class="recharge-title" v-if="slowProList.length!=0">
        <b>分省话费慢充</b>
        <span>1-72小时到账,请耐心等待</span>

        <div class="recharge-title-btn">
          <nut-row>
            <nut-col :span="8" :key="item.id" v-for="item in slowProList" v-on:click="setItem(item)">
              <div :class="{'flex-content':true,active:item.id == obj.id}">
                <span class="recharge-discount">{{100*(userInfo.level?item.vipRate:item.marketRate)}}折</span>
                <b>{{item.amount}}元</b>
                <p>{{userInfo.level?'售':'会员'}}{{(item.amount * item.vipRate).toFixed(2)}}</p>
              </div>
            </nut-col>
          </nut-row>
        </div>
      </div>

      <div class="recharge-title recharge-title-1" v-if="slowList.length!=0">
        <b>全国话费慢充</b>
        <span>1-72小时到账,请耐心等待</span>

        <div class="recharge-title-btn">
          <nut-row>
            <nut-col :span="8" :key="item.id" v-for="item in slowList" v-on:click="setItem(item)">
              <div :class="{'flex-content':true,active:item.id == obj.id}">
                <span class="recharge-discount">{{(100*(userInfo.level?item.vipRate:item.marketRate)).toFixed(2)}}折</span>
                <b>{{item.amount}}元</b>
                <p>{{userInfo.level?'售':'会员'}}{{(item.amount * item.vipRate).toFixed(2)}}</p>
              </div>
            </nut-col>
          </nut-row>
        </div>
      </div>


    <div class="recharge-title recharge-title-1" v-if="fastList.length!=0">
      <b>全国话费快充</b>
      <span>1-24小时到账,请耐心等待</span>
      <div class="recharge-title-btn">
        <nut-row>
          <nut-col :span="8" :key="item.id" v-for="item in fastList"  v-on:click="setItem(item)">
            <div :class="{'flex-content':true,active:item.id == obj.id}">
              <span class="recharge-discount">{{(100*(userInfo.level?item.vipRate:item.marketRate)).toFixed(2)}}折</span>
              <b>{{item.amount}}元</b>
              <p>{{userInfo.level?'售':'会员'}}{{(item.amount * item.vipRate).toFixed(2)}}</p>
            </div>
          </nut-col>
        </nut-row>
      </div>
    </div>

    <div class="recharge-explain">
      <b>充值说明</b>
      <p>慢充到账时间0~72小时内、快充24小时内到账;高峰期慢充延长48小时,快充延长12小时!</p>
      <p v-on:click="$router.push('/login')" style="color: red;" v-if="!token">登录才能查询到充值记录!</p>
      <p v-on:click="$router.push('/upgradeMember')" style="color: red;" v-if="token&&!userInfo.level">点击这里升级会员!</p>
      <p>携号转网，副卡，虚号暂不支持充值，如不慎购买请联系客服退款</p>
      <p>100元以上面额[可能]会分批到账</p>
      <p>请确保手机号码下单正确，错误输入手机号概不退款。不到账5个工作日退款</p>
      <p>同一号码24小时内充值不得超过3次。</p>
      <p>本业务为第三方代充值服务，故无法提供充值发票</p>
    </div>
  </div>

  <div class="recharge-btn">
    <nut-row>
      <nut-col :span="16">
        <div class="flex-content payment">
          {{userInfo.level?'尊享':'应付'}} {{money||0}}元
        </div>
      </nut-col>
      <nut-col :span="8" v-on:click="pay()">
        <div class="flex-content flex-content-light flex-content-btn">
         支付
        </div>
      </nut-col>
    </nut-row>
  </div>

</template>
<script>
    import cookie from '../utils/CookieUtil'

    import {R_parse_PhoneNumber} from "../utils/Regular";

export default {
  name: 'Recharge',
  data(){
    return {
      mobile:"",
      label:"",
      error:true,
      slowProList:[
        {"id":21,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.90,"amount":50,"marketRate":0.92,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":22,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.90,"amount":100,"marketRate":0.92,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":25,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.90,"amount":150,"marketRate":0.92,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"}
        ],
      slowList:[
        {"id":1,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.92,"amount":50,"marketRate":0.94,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":2,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.92,"amount":100,"marketRate":0.94,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":5,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.92,"amount":150,"marketRate":0.94,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"}
        ],
      fastList:[
        {"id":10,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.94,"amount":50,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":12,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.94,"amount":100,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":15,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.94,"amount":150,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"}
      ],
      money:0.0,
      obj:{},
      isp:"",
      recommendUserId:0,
      token:null,
      userInfo:{},
      prov:""
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },
  created(){
    //获取登录token
    this.token = cookie.getCookie(cookie.token);
    this.recommendUserId = this.$route.params.recommendUserId||2;
    if(this.token){
      this.getUserInfo();
    }
    console.log('recommendUserId',this.recommendUserId);
  },
  watch:{
    'mobile'(newValue, oldValue){
          if(newValue.length==11){
            this.error = false;
            let isp = this.verifyMobile(newValue);
          }else{
            this.label = "";
            this.error = true;
          }
      }
  },
  methods:{
      pay(){
        if(this.isp == '' || !R_parse_PhoneNumber.test(this.mobile)){
            this.$toast.fail('请输入正确的号码');
            return;
        }
        if(this.money!=((this.obj.amount * (this.userInfo.level?this.obj.vipRate:this.obj.marketRate)).toFixed(2))){
          this.$toast.fail('请求选择充值金额');
          return;
        }
        let pay_type = this.isWeiXin();

        // let url = '/app/wx';
        let url = '/app/';
        if(pay_type){
          url = url + "wx";
        }else{
          url = url + "ali";
        }

           this.$dialog.confirm({
            title: '充值',
            message:
             `当前要充值的账号为 “${this.mobile}” ,为了避免充值失败,请仔细核对,号码错误充值失败无法退款,确定给这个号码充值吗?`,
          })
            .then(() => {
              this.axios.post(url+'/pay', {
                        isp:this.isp,
                        orderAmt:this.obj.amount,
                        productCode:this.obj.productCode,
                        phoneNumber:this.mobile,
                        recommendUserId:this.recommendUserId,
                        type:this.type,
                        prov:this.obj.province
                      }).then((response) => {
                        if(response!=undefined && response!='' && response!=401 && response!=500 && pay_type){
                            window.location.href = response;
                        }
                        if(response!=undefined && response!='' && response!=401 && response!=500 && !pay_type){
                          document.write(response)
                        }

                          // this.slowList = response;
                      }).catch(function (error) {
                          console.log(error);
                      });
            })
            .catch(() => {
              // on cancel
            });
      },
      setItem(obj){
        this.obj = obj;
        let rate = this.userInfo.level?obj.vipRate:obj.marketRate
        this.money = (obj.amount * rate).toFixed(2);
        this.type= obj.type
      },
      async verifyMobile(mobile){
          await this.axios.get('/app/verifyMobile', {
                  params: {
                    mobile: mobile
                  }
                }).then((response) => {
                  this.isp = response.isp;
                  this.label = response.prov + response.city + response.isp;
                  this.prov = response.prov;
                  this.productconfig(response.isp);
                }).catch(function (error) {
                  console.log(error);
                });
      },
      productconfig(isp){
          this.axios.post('/app/productconfig/list', {
                  isp,prov:this.prov
                }).then((response) => {
                    this.slowList = response.slowList;
                    this.fastList = response.fastList;
                    this.slowProList = response.slowProList;
                    //制空选择的金额
                    this.setItem({amount:0,marketRate:0,vipRate:0});
                }).catch(function (error) {
                    console.log(error);
                });

      },
      getUserInfo(){
        this.axios.post('/app/userInfo', {
            }).then((response) => {
              this.userInfo = response;
              if(this.userInfo.level){
                console.log("等级",response.level);
              }
            }).catch(function (error) {
                console.log(error);
            });
      },
      isWeiXin(){
          //获取浏览器属性
          //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
          let ua = window.navigator.userAgent.toLowerCase();
          //通过正则表达式匹配ua中是否含有MicroMessenger字符串
          if(ua.match(/MicroMessenger/i) == 'micromessenger'){
              return true;
          }
          return false;
      }
  }
}
</script>

<style scoped>
  .van-search__field{
    padding: 10px 0;
  }
.recharge-list-view{
  min-height: 85vh;
  background: #ffffff;
  margin: 10px 10px 50px 10px;
  border-radius: 20px;
  padding: 15px;
}
.recharge-list-view .recharge-title b{
  font-size: 20px;
}
.recharge-title-1{
  margin-top: 20px;
}
.flex-content{
  position:relative;
}
.recharge-discount{
  position: absolute;
  left: -12px;
  top: -2px;
  padding: 0 8px;
  border-radius: 10px 0 10px 0px;
  background: #2BD67E;
  color: #ffffff !important;
  margin: 2px;
}
.recharge-list-view .recharge-title span{
  font-size: 12px;
  color: #cccccc;
  margin-left: 12px;
}
.recharge-title-btn{
  text-align: center;
}
.recharge-title-btn .flex-content{
  border: 1px solid #cccccc;
  margin: 10px 5px;
  padding: 20px 0 5px 0;
  border-radius: 15px;
}
.recharge-title-btn .flex-content p{
  margin: 5px;
  font-size: 12px;
}
.recharge-explain{
  background: #F0FCF8;
  padding: 10px;
}
.recharge-explain p{
  margin: 3px;
  font-size: 13px;
  color: #929292;
}
.recharge-btn{
  background: #ffffff;
  border-top: 1px solid #cccccc;
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.payment{
  margin-left: 20px;
  color: #ff2a2a;
  font-size: 18px;
}
.flex-content-btn{
  background: #2BD67E;
  text-align: center;
  color: #ffffff;
}
.active{
  background: #c9ffd2;
}
</style>
<style lang="less">
  .box{
    .van-field__control--error, .van-field__control--error::placeholder{
       color: #a1a1a1;
     }
     .van-search__content{
       background: #FFFFFF;
     }
     .van-search__field{
       padding: 10px;
       font-size: 19px;
     }
  }

</style>
